import React from "react"
import Header from "./header"
import Helmet from "react-helmet"

const Layout = ({ bodyClassName, children }) => {
  return (
    <>
      <Helmet bodyAttributes={{ class: bodyClassName }} />
      <Header />
      <main className="md:ml-24 px-4 md:px-0">{children}</main>
    </>
  )
}

export default Layout
