import React from "react"
import { Link, animateScroll as scroll, scroller } from "react-scroll"
import Moons from "../images/moons.svg"

const Header = () => (
  <nav className="primary cursor-pointer">
    <div className="flex items-center flex-grow md:justify-center md:flex-row-reverse">
      <Link
        activeClass="border-studio border-b"
        className="nav-item text-studio border-transparent"
        to="studio"
        smooth={true}
        duration={500}
        spy={true}
      >
        Studio
      </Link>
      <Link
        activeClass="border-works border-b"
        className="nav-item text-works border-transparent"
        to="works"
        smooth={true}
        duration={500}
        spy={true}
      >
        Works
      </Link>
      <Link
        activeClass="border-gear border-b"
        className="nav-item text-gear border-transparent"
        to="gear"
        smooth={true}
        duration={500}
        spy={true}
      >
        Gear
      </Link>
    </div>
    <img className="h-6 md:h-10" src={Moons} alt="" />
  </nav>
)

export default Header
