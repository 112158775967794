import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

function SEO({ description, lang, meta, title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: "Niles City Sound is a studio based in Fort Worth, TX",
        },
        {
          property: `og:title`,
          content: "Niles City Sound",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `/og.png`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ].concat(meta)}
    />
  )
}

export default SEO

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}
